$yellow: #FFFCC7; 
$orange: #EF9A48;
$brown: #59322B;

.grocery-app {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: $yellow;
    border-radius: 0.5em;
    margin-top: 4em;
    h1 {
      color: $brown;
    }
  
    .groceries {
      ul {
        list-style: none;
        padding: 0;
  
        li {
          background-color: white;
          margin: 8px 0;
          padding: 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          button {
            background-color: #D54751;
            border-radius: 0.3em;
            color: #fff;
            border: none;
            padding: 6px 12px;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  
    .add-grocery {
      margin-top: 20px;
  
      input {
        padding: 1em;
        border-radius: 0.5em;
        margin-right: 8px;
        background-color: white;
        border:none;
        outline: none;
        font-size: 0.8em;
      }
  
      button {
        background-color: $orange;
        color: #000;
        border: none;
        padding: 0.7em 2em;
        font-size: 1em;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: darken($orange, 10%);
        }
      }
    }
  }
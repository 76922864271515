.share {
  margin: 2vw 5vw;
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    margin-bottom: 20px;
    padding:3em;
    color: white;
    .bottom {
      display: flex;
      justify-content: space-around;
      .left {
        background-color: #D54751;
        padding: 0.8em 4em;
        border-radius: 0.5em;
        height:3em;
      }
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 1em;
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1em;
      }
      button {
        background-color: #D54751;
        padding: 1em 5em;
        border-radius: 0.5em;
        border: none;
          font-size: 1em;
          color: white;
          outline: none;
          @media only screen and (max-width: 1200px) {
            width:100%;
          }
      }
      button:hover {
        background-color: darken(#D54751, 10%);
      }
      .left:hover {
        background-color: darken(#D54751, 10%);
      }
      .left {
        @media only screen and (max-width: 1200px) {
          height:100%;
        }
      }
    }
    input {
      border: none;
      font-size: 1.5em;
      outline: none;
      padding: 1em 0.5em;
      background-color: white;
      border-radius: 0.5em;
      text-align: center;
      width: 100%;
    }
  }
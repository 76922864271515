$yellow: #FFFCC7;
$blue: #4DA394;
$orange: #EF9A48;

.loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    z-index: 1000;
  }
  
  .spinner {
    position: absolute;
    color: white;
  }
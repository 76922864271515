.post {
  margin: 2vw 5vw;
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    color: black;

    .container {
      padding: 5%;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userInfo {
          display: flex;
          gap: 20px;

          .details {
            display: flex;
            flex-direction: column;

            .name {
              font-weight: 500;
            }

            .date {
              font-size: 12px;
            }
          }
        }
      }

      .content {
        margin: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // filter:brightness(110%);
        max-height: 500px;
        img {
          width: 100%;
          max-height: 500px;
          object-fit: cover;
          margin-top: 20px;
        }
        font-size: 1.5em;
      }
      .content img {
        filter:none !important;;
      }
      img {
        filter:none !important;
        // border: 2em solid black;
        filter: none !important;
        -webkit-filter: none !important; 
        mix-blend-mode: normal !important;
      }
      .postImg {
        filter:none;
      }
      .info {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      .item {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        font-size: 14px;
      }
    }
    button {
      background-color: #EF9A48;
      border:none;
      padding: 0.7em 2em;
      border-radius: 0.5em;
    }
    button:hover {  
      background-color: darken(#EF9A48,10%);
    }
}

.comments {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    .write{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin: 20px 0px;

      input{
        flex:5;
        padding: 10px;
        background-color: white;
        outline:none;
        border: none;
        border-radius: 0.5em;
      }

      button{
        border: none;
        background-color: #EF9A48;
        color: white;
        padding: 10px;
        cursor: pointer;
        border-radius: 3px;
      }
      button:hover {
        background-color: darken(#EF9A48,10%);
      }
    }

    .comment {
      margin: 30px 0px;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 1em;

      .info {
        flex:5;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;

        span {
          font-weight: 500;
        }

        p{
          color:black;
        }
      }
      .date{
        flex:1;
        align-self: center;
        color: gray;
        font-size: 12px;
      }
    }
  }
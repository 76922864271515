$yellow: #FFFCC7;
$blue: #4DA394;
$orange: #EF9A48;

.home {
  display: flex;
  flex-wrap: wrap;
  align-items: center; 
  justify-content: space-evenly;
  align-content: space-around;
  row-gap: 1em;
  background-color: $blue;
  margin-top: 3em;
}

.homeCard:hover{
  background-color: darken($orange,5%);
  .link {
    color: white;
  }
}
.homeCard {
    background-color: white;
    width: 30vw;
    height: 50vh;
    border-radius: 0.5em;
    border:0.2em solid $orange;
    padding: 2em;
    margin-bottom: 1em;
    background-color: $yellow;
    h2 {
      font-size: 2em;
    }
    img {
      height: 80%;
      width: 100%;
    }
}
.link {
  text-decoration: none;
  color: #000;
  text-align: center;
}

@media (max-width: 1300px) {
  .homeCard {
    width: 90vw;
    max-height: none;
    img {
      height: 55%;
      width: 100%;
    }
  }
}

//за екрани по-малки от 768px
@media (max-width: 768px) {
  .homeCard {
    width: 90vw;
    max-height: none;
    img {
      height: 60%;
      width: 100%;
    }
  }
}

$yellow: #FFFCC7; 
$blue: #4DA394;
$orange: #EF9A48;
$red: #D54751;
$font-family: 'Arial', sans-serif;

body {
  background-color: #4DA394 !important;
  font-family: $font-family;
}

.start-up-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 0em;
  color: #000;
  text-align: center;
  background-color: white;
  nav {
    background-color: #4DA394;
    padding: 1.2em;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .site-name {
    font-size: 1.5em;
    color: #fff;
    width: 25%;
  }

  .sign-in-button {
    background-color: transparent;
    color: #fff;
    padding: 1em 2em;
    border: none;
    cursor: pointer;
    margin-right: 1em;
    margin-left:5em;
  }

  .sign-in-button:hover {
    background-color: darken(#4DA394, 10%);
  }

  .headerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 1em;
    background-image: url("~/public/upload/background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    img {
      max-width: 40%;
      height: auto;
    }
  }

  h1 {
    font-size: 7em;
    text-transform: uppercase;
    margin-top: 1.5em;
    max-width: 40%;
    margin-left: 0.5em;
  }

  p {
    line-height: 1.5;
    font-size: 1.3em;
  }
  h3 {
    margin-left: 3.5em;
  }
  footer {
    background-color: $blue;
    padding: 1em;
    color: #fff;
    margin-top: auto;
  }

  .main-content {
    background-color: $red;
    padding: 5em;
    font-size: 1.2em;
    flex-grow: 1;
    color: white;
  }

  .logInDiv {
    padding: 2em;
    background-color: $orange;
    flex-grow: 1;
    color: $yellow;
    color:#000;

    button {
      background-color: $yellow;
      color: #000;
      padding: 0.5em 1em;
      margin: 1em 0em 1em;
      font-size: 3em;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      text-transform: uppercase;

      &:hover {
        background-color: darken($red, 10%);
        color: lighten(#fff, 10%);
      }
    }
  }
  .optionCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
    padding-bottom: 3em;
    row-gap: 1em;
    
  }
  .infoContent {
    margin-top: 0;
    h2 {
      margin:2em;
      font-size: 2em;
    }
  }
  .content {
    background-color: white;
  }
  .cards {
    width: 25%;
    border: .2em solid $blue;
    border-radius: 0.5em;
    color:#000;
    min-width: 18em;
    padding: 2em;
    
  }
  @media (max-width: 1200px) {
    .headerDiv {
      flex-direction: column;
      text-align: center;
      align-items: center; 
      h1 {
        font-size: 3em;
        min-width: 100%;
        text-align: center;
        margin-left: 0;
      }
      h3 {
        margin-left: 0;
      }
      img {
        min-width: 80%;
        height: auto;
      }
    }
    .start-up-page {
      nav {
        width: 100%;
      }
      p {
        font-size: 1em;
      }
    }

    .main-content {
      p {
        font-size: 1em;
      }
    }

    footer {
      font-size: 0.8em;
    }
  }
  @media (max-width: 920px) {
    .site-name {
      font-size: 1em;
      color: #fff;
      width: 25%;
      img {
        display: none;
      }
    }
    .sign-in-button  {
      margin-left: 1em;
    }
  }
}

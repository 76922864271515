.conDiv {
    margin-top: 5em;
    input {
        padding: 8px;
        max-width:100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding:0.5em;
        font-size: 1em;
    }
    button {
        background-color: #79C39E;
        color: #fff;
        border: none;
        padding: 1em 3em;
        border-radius: 4px;
        max-width: 50%;
    }
    button:hover{
        background-color: darken(#79C39E, 10%);
    }
}
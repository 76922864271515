$yellow: #FFFCC7; 
$orange: #EF9A48;
$red: #D54751;
$brown: #59322B;

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.registration-form, .login{
  position: absolute; 
  top: 0.5em; 
  min-width: 30%;
  min-height: 35em;
  background-color: $yellow;
  color: #000;
  padding: 2em;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-content: space-evenly;

  @media only screen and (max-width: 1200px) {
    width: 70vw; 
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
    flex-direction: column;
  }

  h2 {
    text-align: center;
    border-radius: 0.5em;
    padding: 1em;
    text-transform: uppercase;
    background-color: $orange;
    color: #000;
  }
  p {
    text-align: center;
    color: red;
  }
  label {
    display: block;
    font-size: 1.5em;
    line-height: 2.5em;
  }
  input {
    width: 80%;
    border-radius: 0.5em;
    padding:1em;
    font-size: 1em;
    border:none;
    margin: 0 auto;
    display: block;
  }
  span {
    margin: 0.5em;
    font-size: 1.2em;
    color: $brown;
    text-align: center;
  }

  button {
    width: 100%;
    padding: 1.5em;
    background-color: $red;
    color: #fff;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 1em;
    text-transform: uppercase;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($red, 10%);
    }
  }
}
.login {
  position: relative;
}
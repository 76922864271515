.NavPage {
    .langSwitch {
        button {
        border: none;
        background-color: #D54751;
        color: #fff;
        padding: 0.7em 1.1em;
        margin-left: 0em;    
        }
        button:hover {
            background-color: darken(#D54751, 10%);
        }
    }
}
.drawerDiv {
    ul {
        list-style: none;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: space-around;
        li {
          margin: 0.5em 0;
          padding: 0.7em;
          border-radius: 0.5em;
        text-transform: uppercase;
        font-size: 1.1em;
        }
      }  
      span {
        color:black;
      }
}